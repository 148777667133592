.App {
    --light-gray: #ebebeb;
    --light-yellow: #e7cb6c;
    --dark-yellow: #ffbb00;
    --logo-yellow: #E0922F;
    --dark-aqua: #679499;
}

.link {
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s;
}

.link:hover {
    color: var(--logo-yellow);
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
