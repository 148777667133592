.header {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 2000;
    height: 80px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
    background-color: #ffffff;
}

.header .logo {
    width: 25vw;
    padding-left: 5vw;
    height: 100%;
    align-items: center;
}

.header .logo img {
    height: 60px;
    width: 60px;
    cursor: pointer;
}

.header .logo .titulo {
    font-size: 17.5px;
    color: #000000;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    cursor: pointer;
}

.header .logo .subtitulo {
    font-size: 10px;
    color: #000000;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    cursor: pointer;
}

.header ul {
    height: 100%;
    align-items: center;
    width: 60vw;
}

.link {
    color: #000;
}

.header ul li {
    font-family: "Montserrat", sans-serif;
    text-decoration: none;
    height: 100%;
    padding-top: 28px;
    padding-bottom: 28px;
    width: 15vw;
    cursor: pointer;
    transition: all 0.4s;
}

.header ul li:hover {
    color: white;
    font-weight: 600;
    background-color: rgb(209, 209, 209);
}

@media screen and (min-width:769px) and (max-width:1370px){
    .header {
        margin-top: 2px;
        height: 60px;
        box-shadow: none;
    }

    .header ul li {
        font-size: 13px;
        padding-top: 22px;
        padding-bottom: 22px;
    }

    .header .logo img {
        height: 56px;
        width: 56px;
    }

    .header .logo .titulo {
        font-size: 16px;
    }
    
    .header .logo .subtitulo {
        font-size: 9px;
    }
}

@media screen and (max-width: 768px) {
    .header {
        display: none;
    }

}
