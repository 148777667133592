@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600&display=swap);
.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/***********************RESET******************************/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

* {
    box-sizing: border-box;
}

.flex {
    display: flex;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.App {
    --light-gray: #ebebeb;
    --light-yellow: #e7cb6c;
    --dark-yellow: #ffbb00;
    --logo-yellow: #E0922F;
    --dark-aqua: #679499;
}

.link {
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s;
}

.link:hover {
    color: var(--logo-yellow);
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.header {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 2000;
    height: 80px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
    background-color: #ffffff;
}

.header .logo {
    width: 25vw;
    padding-left: 5vw;
    height: 100%;
    align-items: center;
}

.header .logo img {
    height: 60px;
    width: 60px;
    cursor: pointer;
}

.header .logo .titulo {
    font-size: 17.5px;
    color: #000000;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    cursor: pointer;
}

.header .logo .subtitulo {
    font-size: 10px;
    color: #000000;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    cursor: pointer;
}

.header ul {
    height: 100%;
    align-items: center;
    width: 60vw;
}

.link {
    color: #000;
}

.header ul li {
    font-family: "Montserrat", sans-serif;
    text-decoration: none;
    height: 100%;
    padding-top: 28px;
    padding-bottom: 28px;
    width: 15vw;
    cursor: pointer;
    transition: all 0.4s;
}

.header ul li:hover {
    color: white;
    font-weight: 600;
    background-color: rgb(209, 209, 209);
}

@media screen and (min-width:769px) and (max-width:1370px){
    .header {
        margin-top: 2px;
        height: 60px;
        box-shadow: none;
    }

    .header ul li {
        font-size: 13px;
        padding-top: 22px;
        padding-bottom: 22px;
    }

    .header .logo img {
        height: 56px;
        width: 56px;
    }

    .header .logo .titulo {
        font-size: 16px;
    }
    
    .header .logo .subtitulo {
        font-size: 9px;
    }
}

@media screen and (max-width: 768px) {
    .header {
        display: none;
    }

}

.carousel-container {
    width: 100%;
    height: 84vh;
    position: relative;
    background-color: #DCDDDD;
}

.carousel-container .logo-div {
    position: absolute;
    z-index: 3;
    right: 0;
    top: 0;
    height: 100%;
    max-width: 29%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel-container .logo-div .logo {
    width: 60%;
}

@media screen and (min-width:1800px){
    .carousel-container .logo-div {
        width: 78%;
    }
    .carousel-container .logo-div .logo {
        width: 80%;
    }
}
@media screen and (min-width:1097px) and (max-width:1799px){
    .carousel-container .logo-div {
        width: 78%;
    }
    .carousel-container .logo-div .logo {
        width: 90%;
    }
}
@media screen and (min-width:769px) and (max-width:1096px){
    /* .carousel-container .logo-div {
        max-width: 78%;
    } */
    .carousel-container .logo-div .logo {
        width: 90%;
    }
}
@media screen and (min-width:525px) and (max-width:768px){
    .carousel-container .logo-div {
        justify-content: flex-end;
        align-items: flex-end;
        max-width: 78%;
    }
    .carousel-container .logo-div .logo {
        width: 60%;
        margin-bottom: 20%;
    }
}
@media screen and (min-width:321px) and (max-width:524px){
    .carousel-container .logo-div {
        justify-content: flex-end;
        align-items: flex-end;
        max-width: 100%;
    }
    .carousel-container .logo-div .logo {
        width: 70%;
        margin-bottom: 20%;
    }
}

.home_panel {
    width: 100%;
    height: 84vh;
    position: relative;
    overflow: hidden;
    background-color: #DCDDDD;
}

.home_panel.background {
    position: relative;
}

.home_panel .background .image {
    position: absolute;
    z-index: 2;
    left: 0;
    height: 100vh;
    width: 100%;
    opacity: 0.7;
    -webkit-clip-path: polygon(0 0, 80% 0, 60% 100%, 0% 100%);
            clip-path: polygon(0 0, 80% 0, 60% 100%, 0% 100%);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 25% 75%;
}

.home_panel .background .first-image {
    background-position: 25% 40%;
}

.home_panel .background .third-image {
    background-position: 0% 5%;
}

.home_panel .background .logo-carousel-container {
    position: absolute;
    z-index: 3;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.home_panel .background .logo-carousel-container .logo-carousel-img {
    width: 90%;
}

@media screen and (min-width:1800px){
    .home_panel .background .logo-carousel-container {
        right: 200px;
    }
    .home_panel .background .logo-carousel-container .logo-carousel-img {
        width: 100%;
    }
    .home_panel .background .third-image {
        background-position: 70%;
    }
}
@media screen and (min-width:769px) and (max-width:1370px){
    .home_panel .background .logo-carousel-container .logo-carousel-img {
        width: 70%;
    }
    .home_panel .background .third-image {
        background-position: 70%;
    }
}
@media screen and (min-width:525px) and (max-width:768px){
    .home_panel .background .image {
        -webkit-clip-path: polygon(0 0, 100% 0, 22% 100%, 0 100%);
                clip-path: polygon(0 0, 100% 0, 22% 100%, 0 100%);
    }
    .home_panel .background .first-image {
        background-position: 30%;
    }
    .home_panel .background .second-image {
        background-position: 30%;
    }
    .home_panel .background .third-image {
        background-position: 70%;
    }
    .home_panel .background .logo-carousel-container {
        align-items: flex-end;
        padding-bottom: 5vh;
    }
    .home_panel .background .logo-carousel-container .logo-carousel-img {
        width: 60%;
    }
}
@media screen and (max-width:524px){
    .home_panel .background .image {
        -webkit-clip-path: polygon(0 0, 100% 0, 0 84%, 0 84%);
                clip-path: polygon(0 0, 100% 0, 0 84%, 0 84%);
    }
    .home_panel .background .first-image {
        background-position: 30%;
    }
    .home_panel .background .second-image {
        background-position: 70%;
    }
    .home_panel .background .third-image {
        background-position: 70%;
    }
    .home_panel .background .logo-carousel-container {
        align-items: flex-end;
        padding-bottom: 5vh;
    }
    .home_panel .background .logo-carousel-container .logo-carousel-img {
        width: 80%;
    }
}

@media screen and (max-width: 768px) {
    /* .home_panel .background .image {
        clip-path: polygon(0 0, 100% 0, 0 100%, 0% 100%);
    } */
}

.footer {
    width: 100%;
    background-color: #525252;
    min-height: 16vh;
    align-items: center;
    justify-content: space-between;
    padding-left: 15%;
    padding-right: 15%;
}

.footer p {
    font-family: "Poppins", sans-serif;
    color: var(--light-gray);
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 3px;
}

.footer p span {
    font-weight: 500;
}

.footer .block {
    align-items: flex-start;
}

.footer .coffee {
    width: 100%;
    align-self: center;
    justify-content: flex-start;
    cursor: pointer;
}

.footer .coffee_icon {
    margin-left: 8px;
    margin-top: 1px;
    cursor: pointer;
    transition: all 0.3s;
    color: #ffffff;
}

.footer .coffee_icon:hover {
    color: var(--logo-yellow);
}

.footer .icons {
    width: 100%;
    margin-bottom: 8px;
    align-items: center;
    justify-content: center;
}

.footer .icon {
    color: #ffffff;
    height: 35px;
    width: 35px;
    transition: all 0.3s;
    cursor: pointer;
}

.footer .icon:hover {
    color: #ffbb00;
}


@media screen and (min-width:1371px){
    .footer p span{
        font-size: 12px;
        color: var(--light-gray);
    }
}

@media screen and (min-width:769px) and (max-width:1370px){

    .footer{
        min-height: 20vh;
    }

    .footer p {
        font-size: 12px;
    }

    .footer p span{
        font-size: 12px;
        color: var(--light-gray);
    }
}

@media screen and (max-width: 768px) {
    .footer {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        flex-direction: column;
        padding: 50px 30px;
    }

    .footer .block {
        margin-bottom: 30px;
        text-align: center;
        align-items: center;
    }

    .footer .block:last-child {
        margin-bottom: 0;
    }

    .footer p span {
        color: var(--logo-yellow);
    }

    .footer .icon {
        color: var(--logo-yellow);
    }
}


.contato_form {
    min-height: 84vh;
    height: auto;
    position: relative;
}

.contato_form .background {
    position: absolute;
    left: 0;
    z-index: -1;
    height: 100%;
    width: 50vw;
    background-color: #ffffff;
    opacity: 0.75;
    -webkit-clip-path: polygon(0 0, 52% 0, 100% 100%, 0 100%);
            clip-path: polygon(0 0, 52% 0, 100% 100%, 0 100%);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    background-image: url(../../static/media/2.8b092946.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}

.contato_form .form {
    width: 40%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    /* top: 100px; */
    right: 3%;
    padding: 40px;
}

.contato_form .form h1 {
    font-family: "Poppins", sans-serif;
    text-align: center;
    font-weight: 600;
    color: var(--logo-yellow);
    font-size: 45px;
    margin-bottom: 8px;
}

.contato_form .form h2 {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    margin-bottom: 2px;
    font-size: 22px;
}

.contato_form .form h3 {
    margin-top: 20px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: var(--logo-yellow);
    margin-bottom: 5px;
    font-size: 32px;
}

.contato_form .form p {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 20px;
}

.contato_form .form a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: inherit;
}

.contato_form .form a .whatsapp {
    color: #128C7E;
    font-size: 22px;
}

.contato_form .form a .phone:hover,
.contato_form .form a .phone:active {
    color: var(--logo-yellow);
}

.contato_form .form .budget-btn {
    background-color: var(--logo-yellow);
    border:none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    padding: 20px;
    margin: 25px;
}

.contato_form .form .budget-btn:hover,
.contato_form .form .budget-btn:active {
    background-color: var(--dark-yellow);
}

.contato_form .form .icons {
    width: 100%;
    margin-bottom: 8px;
    align-items: center;
    justify-content: center;
}

.contato_form .form .icon {
    color: #525252;
    height: 40px;
    width: 40px;
    transition: all 0.3s;
    cursor: pointer;
    margin: 0 2px;
}

.contato_form .form .icon:hover {
    color: var(--logo-yellow);
}

@media screen and (min-width:769px) and (max-width:1370px){

    .contato_form{
        min-height: 60vh;
    }

    .contato_form .form{
        padding: 10px;
    }
    .contato_form .form h1{
        font-size: 30px;
    }

    .contato_form .form h2,
    .contato_form .form a .whatsapp{
        font-size: 15px;
    }
}

@media screen and (max-width: 768px) {
    .contato_form {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        min-height: 60vh;
        padding-bottom: 40px;
    }

    .contato_form .background {
        display: none;
    }

    .contato_form .form {
        position: relative;
        top: 50px;
        width: 100%;
        right: 0;
    }

    .contato_form .form h1 {
        font-size: 32px;
    }

    .contato_form .form h2,
    .contato_form .form a .whatsapp {
        font-size: 20px;
    }

    .contato_form .form h3 {
        font-size: 24px;
    }
}



.portifolio {
    margin-top: 50px;
    min-height: calc(100% - 50px);
    height: auto;
    width: 100%;
}

.portifolio .center_text {
    width: 100%;
    text-align: left;
    margin-bottom: 50px;
}

.portifolio .grey_block {
    background-color: #f4f4f4;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.portifolio .parte1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    min-height: 100vh;
    height: auto;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.portifolio .section {
    margin-bottom: 50px;
}

.portifolio .first_panel {
    justify-content: space-between;
}

.portifolio .parte1 .parte1_title {
    width: 100%;
    text-align: left;
    margin-bottom: 50px;
}

.portifolio .parte1 .box {
    position: relative;
    width: 96%;
    height: 400px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    transition: ease-in-out 0.3s;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.portifolio .parte1 .box img {
    opacity: 0.8;
    width: 55%;
    height: auto;
}

.portifolio .parte1 .box .title {
    bottom: 30px;
    right: 30px;
    font-size: 24px;
    position: absolute;
    color: #ffffff;
    font-weight: bold;
    font-family: "Poppins", sans-serif;
}

.portifolio .description {
    background-color: #e3e3e1;
    font-family: "Poppins", sans-serif;
    text-align: left;
    width: 450px;
    margin-top: 30px;
    padding: 20px;
    border-radius: 4px;
    margin-left: 15px;
    margin-right: 15px;
}

.blue {
    color: #e7f3f5;
}

.portifolio .parte1 .box:hover {
    transform: scale(1.01);
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
}

.portifolio .block_1 {
    background-color: var(--logo-yellow);
}
.portifolio .block_2 {
    background-color: var(--logo-yellow);
}

.portifolio .section_title {
    text-align: left;
    margin-bottom: 20px;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 40px;
}

.portifolio .section_description {
    text-align: left;
    margin-bottom: 40px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 20px;
}

.portifolio .subsection {
    height: auto;
    /* min-height: 100vh; */
    padding-top: 14vh;
    margin-left: 10%;
    margin-right: 10%;
    padding-bottom: 100px;
    display: flex-column;
    align-items: center;
    justify-content: center;
}

.portifolio .sub_subsection {
    height: auto;
    /* min-height: 100vh; */
    padding-top: 14vh;
    margin-left: 10%;
    margin-right: 10%;
    padding-bottom: 100px;
    display: flex-column;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 50px;
}

.portifolio .subsection_title {
    text-align: left;
    text-transform: capitalize;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 40px;
}

.portifolio .subsection_description {
    text-align: left;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 40px;
}

.portifolio .projetos {
    justify-content: left;
}

.portifolio .projetos .section_options {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
}

.portifolio .projetos .section_options .section_box {
    height: 40vh;
    width: 32%;
    position: relative;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    transition: ease-in-out 0.3s;
    margin: 10px;
    align-items: center;
    justify-content: center;
}

.portifolio .projetos .section_options .section_box:hover {
    transform: scale(1.01);
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.7);
}

.portifolio .projetos .section_options .section_box .box_img {
    opacity: 0.8;
    width: 55%;
    height: auto;
}

.portifolio .projetos .section_options .section_box .title {
    bottom: 20px;
    right: 30px;
    font-size: 24px;
    position: absolute;
    color: #ffffff;
    font-weight: bold;
    font-family: "Poppins", sans-serif;
}

.portifolio .project_galery {
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.portifolio .project_galery .project {
    position: relative;
    height: 250px;
    width: 31.5%;
    margin-left: 0.75%;
    margin-right: 0.75%;
    min-width: 300px;
    margin-bottom: 30px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    background-color: #626397;
    overflow: hidden;
    transition: all 0.2s;
}

.portifolio .project_galery .project img {
    width: 100%;
    height: 100%;
}

.portifolio .project_galery .project:hover {
    transform: scale(1.01);
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.7);
}

.portifolio .project_galery .project .project_name {
    bottom: 30px;
    right: 30px;
    font-size: 24px;
    position: absolute;
    color: #ffffff;
    text-align: right;
    font-weight: bold;
    font-family: "Poppins", sans-serif;
}

.portifolio .title_line {
    align-self: center;
    margin-bottom: 20px;
}

.portifolio .title_line .section_icon {
    height: 50px;
    margin-right: 20px;
}

@media screen and (min-width:769px) and (max-width:1370px){
    
    .portifolio .section_description{
        font-size: 18px;
    }
    .portifolio .subsection_description{
        font-size: 18px;
    }
    .portifolio .project_galery .project .project_name{
        font-size: 20px;
    }

    .portifolio .parte1 .box{
        height: 300px;
    }

    .portifolio .section_title{
        font-size: 36px;
    }

    .portifolio .subsection_title{
        font-size: 36px;
    }

    .portifolio .title_line .section_icon{
        height: 44px;
    }

    .portifolio .parte1 .box .title{
        font-size: 20px;
        max-width: 80%;
        text-align: right;
    }

    .portifolio .project_galery .project .project_name{
        width: 80%;
    }

    .portifolio .projetos .section_options .section_box{
        height: 32vh;
    }

    .portifolio .project_galery .project{
        height: 32vh;
        min-width: 0px;
        width: 30.5%;
    }

    .portifolio .project_galery .project img {
        object-fit: cover;
    }
}

@media screen and (max-width: 768px) {
    .portifolio {
        margin-top: 150px;
        width: 100%;
    }

    .portifolio .first_panel {
        flex-direction: column;
    }

    .portifolio .section {
        box-sizing: border-box;
        width: 100%;
    }

    .portifolio .parte1 .parte1_title {
        font-size: 36px;
    }

    .portifolio .section_title {
        font-size: 36px;
        text-align: center;
    }

    .portifolio .subsection_title {
        font-size: 36px;
    }

    .portifolio .title_line .section_icon{
        display: none;
    }

    .portifolio .parte1 .box img {
        width: 38%;
        height: auto;
        margin-bottom: 1%;
    }

    .portifolio .parte1 .box .title {
        text-align: right;
        width: 60%;
        font-size: 22px;
        bottom: 20px;
    }

    .portifolio .description {
        width: 100%;
    }

    .portifolio .parte1 .box {
        height: 200px;
        width: 100%;
    }

    .portifolio .projetos .section_options {
        flex-direction: column;
    }

    .portifolio .projetos .section_options .section_box {
        width: 100%;
        height: 200px;
        margin-bottom: 20px;
    }

    .portifolio .projetos .section_options .section_box .box_img {
        width: 30%;
        height: auto;
        margin-bottom: 1%;
    }

    .portifolio .projetos .section_options .section_box .title {
        bottom: 10px;
    }

    .portifolio .project_galery .project {
        width: 100%;
        height: 180px;
    }

    .portifolio .project_galery .project img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}


.showListElement {
    display: block;
}

.hideListElement {
    display: none;
}

.arrow {
    width: 0; 
    height: 0; 
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 9px solid var(--logo-yellow);
    margin-left: 6px;
    transition: all 0.1s 0s;
}

.toggle-right {
    transform: rotate(0deg);
}

.toggle-down {
    transform: rotate(90deg);
}

.apresentacao {
    margin-top: 60px;
    min-height: 100vh;
    width: 100%;
}

.apresentacao .scrollable {
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
}

.apresentacao h1 {
    font-size: 64px;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 10px;
}

.apresentacao .block_1 .info {
    position: absolute;
    padding: 30px;
    padding-left: 7vw;
    width: 40vw;
    height: 100%;
}

.apresentacao .block_1 .info .servico {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: "Poppins", sans-serif;
    padding: 8px 0;
}

.apresentacao .block_1 .info .servico .titulo {
    font-family: "Poppins", sans-serif;
    text-align: left;
    font-weight: 600;
    font-size: 32px;
    margin-bottom: 10px;
    color: var(--logo-yellow);
    cursor: pointer;
    display: flex;
    align-items: center;
}

.apresentacao .block_1 .info .servico .descricao {
    text-align: justify;
    padding-right: 12px;
    font-size: 16px;
}

.apresentacao .block_1 .info .servico .subdescricao {
    text-align: justify;
    padding-right: 12px;
    font-size: 16px;
    padding-top: 4px;
}

.apresentacao .block_1 {
    min-height: 100vh;
    margin-bottom: 20px;
    position: relative;
}

.apresentacao .block_1 h1 {
    color: var(--logo-yellow);
    text-align: left;
}

.apresentacao .block_1 .background_image {
    position: absolute;
    right: 0;
    z-index: -1;
    height: 100%;
    width: 60vw;
    background-color: #ffffff;
    opacity: 0.75;
    -webkit-clip-path: polygon(52% 0, 100% 0, 100% 100%, 0 100%);
            clip-path: polygon(52% 0, 100% 0, 100% 100%, 0 100%);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    background-repeat: no-repeat;
    background-size: cover;
}

.apresentacao .block_1 .default_background_image {
    background-image: url(../../static/media/1.5b98b843.jpg);
}
.apresentacao .block_1 .arquitetonico_background_image {
    background-image: url(../../static/media/arquitetonico.c142b304.png);
}
.apresentacao .block_1 .interiores_background_image {
    background-image: url(../../static/media/interiores.4dde0474.png);
}
.apresentacao .block_1 .legal_background_image {
    background-image: url(../../static/media/legal.c0abb9ba.png);
}
.apresentacao .block_1 .consultoria_background_image {
    background-image: url(../../static/media/consultoria.2441847a.png);
}
.apresentacao .block_1 .modelagem_background_image {
    background-image: url(../../static/media/modelagem.1a25547c.png);
}
.apresentacao .block_1 .acompanhamento_background_image {
    background-image: url(../../static/media/1.5b98b843.jpg);
}

.apresentacao .block_2 {
    min-height: 100vh;
    position: relative;
}

.apresentacao .block_2 .info .curriculo {
    width: 75%;
    align-items: flex-start;
}

.apresentacao .block_2 .info .curriculo .curso {
    align-items: flex-start;
    text-align: justify;
    margin-bottom: 20px;
}

.apresentacao .block_2 .info .section_title {
    font-family: "Poppins", sans-serif;
    text-align: left;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 10px;
    color: var(--dark-aqua);
}

.apresentacao .block_2 .info .curriculo .curso h4 {
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    padding: 2px 0;
}

.apresentacao .block_2 .info .curriculo .curso .titulo {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
}

.apresentacao .block_2 .info .curriculo .curso .local {
    font-family: "Poppins", sans-serif;
    font-style: italic;
    margin-bottom: 3px;
}

.apresentacao .block_2 .info .curriculo .curso .titulo {
    font-family: "Poppins", sans-serif;
}

.apresentacao .block_2 .info .curriculo .curso .enfase {
    font-family: "Poppins", sans-serif;
}

.apresentacao .block_2 .info {
    position: absolute;
    padding: 30px;
    right: 0;
    z-index: 1;
    width: 50vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10%;
}

.apresentacao .block_2 h1 {
    color: var(--logo-yellow);
}

.apresentacao .block_2 .background_image {
    position: absolute;
    left: 0;
    z-index: -1;
    height: 100%;
    width: 60vw;
    background-color: #ffffff;
    opacity: 0.75;
    -webkit-clip-path: polygon(0 0, 100% 0, 52% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 52% 100%, 0 100%);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    /* background-image: url("./images/2.jpg"); */
    background-image: url(../../static/media/apresentacao.0f62fed6.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 20%;
}

/**MOBILE**/

@media screen and (max-width: 768px) {
    .mobile {
        margin-top: 60px;
        min-height: 100vh;
        width: 100%;
    }

    .mobile .scrollable {
        max-height: 70vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .mobile h1 {
        font-size: 64px;
        font-family: "Poppins", sans-serif;
        font-weight: 700;
        /* margin-top: 30px; */
        /* margin-bottom: 50px; */
    }

    .mobile .block_1 .info {
        padding: 30px;
        padding-left: 7vw;
        width: 100%;
        height: auto;
    }

    .mobile .block_1 .info p {
        font-size: 18px;
        padding-left: 20px;
        font-family: "Poppins", sans-serif;
        text-align: justify;
    }

    .mobile .block_1 {
        min-height: 60vh;
        margin-bottom: 20px;
        /* position: relative; */
    }

    .mobile .block_1 h1 {
        color: var(--logo-yellow);
    }

    .mobile .block_1 .info {
        min-height: 60vh;
    }

    .mobile .block_1 .info .servicos {
        margin-left: 5%;
        width: 90%;
        align-items: flex-start;
    }

    .mobile .block_1 .info .servico {
        font-family: "Poppins", sans-serif;
        padding: 8px 0;
    }

    .mobile .block_1 .info .servico .titulo {
        text-align: left;
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 10px;
        color: var(--logo-yellow);
        display: flex;
        align-items: center;
    }


    .mobile .block_1 .info .servico .descricao {
        text-align: justify;
        font-size: 16px;
    }
    
    .mobile .block_1 .info .servico .subdescricao {
        text-align: justify;
        padding-top: 4px;
        font-size: 16px;
    }

    .mobile .block_2 .info .curriculo {
        margin-left: 5%;
        width: 90%;
        align-items: flex-start;
    }

    .mobile .block_2 .info .curriculo .curso {
        align-items: flex-start;
        text-align: justify;
        margin-bottom: 20px;
    }

    .mobile .block_2 .info .section_title {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 10px;
        color: var(--logo-yellow);
    }

    .mobile .block_2 .info .curriculo .curso .titulo {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
    }

    .mobile .block_2 .info .curriculo .curso h4 {
        font-family: "Poppins", sans-serif;
    }

    .mobile .block_2 .info .curriculo .curso .local {
        font-family: "Poppins", sans-serif;
        font-style: italic;
        margin-bottom: 3px;
    }

    .mobile .block_2 .info .curriculo .curso .titulo {
        font-family: "Poppins", sans-serif;
    }

    .mobile .block_2 .info .curriculo .curso .enfase {
        font-family: "Poppins", sans-serif;
    }
/* 
    .mobile .block_2 .info {
        position: absolute;
        padding: 30px;
        right: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
    } */

    .mobile .block_2 h1 {
        color: var(--logo-yellow);
    }
}

@media screen and (min-width:769px) and (max-width:1200px) {
    .apresentacao .block_1 h1 {
        font-size: 50px;
    }
    .apresentacao .block_2 h1 {
        font-size: 40px;
    }
    .apresentacao .block_1 .info .servico .titulo {
        font-size: 24px;
    }
}
@media screen and (max-width: 768px) {
    .apresentacao .block_1 h1 {
        display: none;
    }
    .apresentacao .block_2 h1 {
        display: none;
    }
    .mobile .block_1 h1 {
        font-size: 50px;
    }
    .mobile .block_2 h1 {
        font-size: 40px;
    }
}

/* @media screen and (max-width: 768px) {
    .apresentacao {
        margin-top: 60px;
        min-height: 100vh;
        width: 100%;
    }

    .apresentacao .scrollable {
        max-height: 70vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .apresentacao h1 {
        font-size: 64px;
        font-family: "Poppins", sans-serif;
        font-weight: 700;
        margin-top: 30px;
        margin-bottom: 50px;
    }

    .apresentacao .block_1 .info {
        position: absolute;
        padding: 30px;
        padding-left: 7vw;
        width: 100%;
        height: auto;
    }

    .apresentacao .block_1 .info p {
        font-size: 18px;
        padding-left: 20px;
        font-family: "Poppins", sans-serif;
        text-align: justify;
    }

    .apresentacao .block_1 {
        min-height: 60vh;
        margin-bottom: 20px;
        position: relative;
    }

    .apresentacao .block_1 h1 {
        color: var(--dark-yellow);
    }

    .apresentacao .block_2 {
        min-height: 100vh;
        position: relative;
    }

    .apresentacao .block_2 .info .curriculo {
        margin-left: 10%;
        width: 90%;
        align-items: flex-start;
    }

    .apresentacao .block_2 .info .curriculo .curso {
        align-items: flex-start;
        text-align: left;
        margin-bottom: 20px;
    }

    .apresentacao .block_2 .info .section_title {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 10px;
        color: var(--dark-aqua);
    }

    .apresentacao .block_2 .info .curriculo .curso .titulo {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
    }

    .apresentacao .block_2 .info .curriculo .curso .local {
        font-family: "Poppins", sans-serif;
        font-style: italic;
        margin-bottom: 3px;
    }

    .apresentacao .block_2 .info .curriculo .curso .titulo {
        font-family: "Poppins", sans-serif;
    }

    .apresentacao .block_2 .info .curriculo .curso .enfase {
        font-family: "Poppins", sans-serif;
    }

    .apresentacao .block_2 .info {
        position: absolute;
        padding: 30px;
        right: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
    }

    .apresentacao .block_2 h1 {
        color: var(--dark-aqua);
    }
} */
.projeto_component {
    margin-top: 100px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.projeto_component .titulo {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    text-align: left;
    font-family: "Poppins", sans-serif;
    font-size: 60px;
}

.projeto_component .banner_principal {
    width: 100%;
    height: 50vh;
    background-color: #ffffff;
    position: relative;
}

.projeto_component .banner_principal .img_principal {
    position: absolute;
    object-fit: cover;
    margin: 0;
    -webkit-clip-path: polygon(0 0, 100% 0, 79% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 79% 100%, 0 100%);
    z-index: 2;
    left: 0;
    width: 65%;
    height: 100%;
    background-color: #381d1d;
}

.projeto_component .banner_principal .description {
    position: absolute;
    z-index: 3;
    -webkit-clip-path: polygon(30% 0, 100% 0, 100% 100%, 0 100%);
            clip-path: polygon(30% 0, 100% 0, 100% 100%, 0 100%);
    right: 0;
    width: 46%;
    height: 100%;
    background-color: #e3e3e1;
}

.projeto_component .banner_principal .description .texto {
    margin-left: 34%;
    margin-right: 10%;
    margin-top: 6%;
    text-align: justify;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    width: 56%;
}

.projeto_component .additional_info {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.projeto_component .additional_info .bloco {
    height: 12vh;
    width: 300px;
    background-color: var(--logo-yellow);
    border-radius: 3px;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.3);
}

.projeto_component .additional_info .bloco:last-child {
    margin-left: 30px;
}

.projeto_component .additional_info .bloco .bloco_titulo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35%;
    height: 100%;
    color: white;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 20px;
}

.projeto_component .additional_info .bloco .bloco_info {
    display: flex;
    text-align: left;
    align-items: center;
    justify-content: left;
    width: 60%;
    margin-left: 5%;
    height: 100%;
    color: white;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 16px;
}

.projeto_component .additional_info .bloco .ano {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 60%;
    margin-left: 5%;
    height: 100%;
    color: white;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 30px;
}

@media screen and (min-width:769px) and (max-width:1370px){
    .projeto_component .titulo {
        font-size: 50px;
    }

    .projeto_component .banner_principal .description .texto{
        font-size:  16px;
    }

    .projeto_component .additional_info .bloco .bloco_titulo{
        font-size: 16px;
    }

    .projeto_component .additional_info .bloco .bloco_info{
        font-size: 16px;
    }

    .projeto_component .additional_info .bloco{
        height: 16vh;
    }
}

/**MOBILE**/

.projeto_component_mobile {
    margin-top: 100px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.projeto_component_mobile .titulo {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    text-align: left;
    font-family: "Poppins", sans-serif;
    font-size: 40px;
}

.projeto_component_mobile .banner_principal {
    width: 100%;
    height: auto;
    background-color: #ffffff;
    flex-direction: column;
}

.projeto_component_mobile .banner_principal .img_principal {
    object-fit: cover;
    width: 100%;
    height: auto;
    background-color: #381d1d;
}

.projeto_component_mobile .banner_principal .description {
    width: 100%;
    height: 100%;
    background-color: #f1f1f1;
    padding: 30px 15px;
}

.projeto_component_mobile .banner_principal .description .texto {
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    width: 85%;
}

.projeto_component_mobile .additional_info {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
}

.projeto_component_mobile .additional_info .bloco {
    height: 12vh;
    width: 100%;
    background-color: var(--logo-yellow);
    border-radius: 3px;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
}

.projeto_component_mobile .additional_info .bloco .bloco_titulo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35%;
    height: 100%;
    color: white;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 20px;
}

.projeto_component_mobile .additional_info .bloco .bloco_info {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 60%;
    margin-left: 5%;
    height: 100%;
    color: white;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 20px;
}

.projeto_component_mobile .additional_info .bloco .ano {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 60%;
    margin-left: 5%;
    height: 100%;
    color: white;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 30px;
}

.banner_principal .description {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
}

.project_gallery {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 768px) {
    .project_gallery {
        width: 85%;
        margin-top: 10px;
    }
}

element.style {
    object-fit: cover;
}
.header {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 2000;
    height: 80px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
    background-color: #ffffff;
}

.header .logo {
    width: 25vw;
    padding-left: 5vw;
    height: 100%;
    align-items: center;
}

.header .logo img {
    height: 60px;
    width: 60px;
    cursor: pointer;
}

.header .logo .titulo {
    font-size: 17.5px;
    color: #000000;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    cursor: pointer;
}

.header .logo .subtitulo {
    font-size: 10px;
    color: #000000;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    cursor: pointer;
}

.header ul {
    height: 100%;
    align-items: center;
    width: 60vw;
}

.link {
    color: #000;
}

.header ul li {
    font-family: "Montserrat", sans-serif;
    text-decoration: none;
    height: 100%;
    padding-top: 28px;
    padding-bottom: 28px;
    width: 15vw;
    cursor: pointer;
    transition: all 0.4s;
}

.header ul li:hover {
    color: white;
    font-weight: 600;
    background-color: rgb(209, 209, 209);
}

@media screen and (min-width:769px) and (max-width:1370px){
    .header {
        margin-top: 2px;
        height: 60px;
        box-shadow: none;
    }

    .header ul li {
        font-size: 13px;
        padding-top: 22px;
        padding-bottom: 22px;
    }

    .header .logo img {
        height: 56px;
        width: 56px;
    }

    .header .logo .titulo {
        font-size: 16px;
    }
    
    .header .logo .subtitulo {
        font-size: 9px;
    }
}

@media screen and (max-width: 768px) {
    .header {
        display: none;
    }

}

